:root {
  --color-white: 255 255 255;
    --color-gray-0: 250 249 248;
    --color-gray-50: 249 247 246;
    --color-gray-100: 247 245 244;
    --color-gray-200: 238 235 234;
    --color-gray-300: 218 214 213;
    --color-gray-400: 175 172 171;
    --color-gray-500: 112 110 109;
    --color-gray-600: 68 67 66;
    --color-gray-700: 46 45 45;
    --color-gray-800: 35 34 34;
    --color-gray-900: 31 30 30;
    --color-red-0: 255 246 244;
    --color-red-50: 255 211 207;
    --color-red-100: 255 177 171;
    --color-red-200: 246 143 135;
    --color-red-300: 228 108 99;
    --color-red-400: 208 72 65;
    --color-red-500: 178 45 48;
    --color-red-600: 148 8 33;
    --color-red-700: 118 0 18;
    --color-red-800: 90 0 0;
    --color-red-900: 66 0 0;
    --color-yellow-0: 252 249 233;
    --color-yellow-50: 248 229 185;
    --color-yellow-100: 239 192 120;
    --color-yellow-200: 229 153 62;
    --color-yellow-300: 217 121 23;
    --color-yellow-400: 187 85 4;
    --color-yellow-500: 152 55 5;
    --color-yellow-600: 118 43 11;
    --color-yellow-700: 87 31 13;
    --color-yellow-800: 58 22 7;
    --color-yellow-900: 58 22 7;
    --color-orange-0: 255 250 238;
    --color-orange-50: 254 227 192;
    --color-orange-100: 248 184 134;
    --color-orange-200: 245 146 94;
    --color-orange-300: 229 111 74;
    --color-orange-400: 196 76 52;
    --color-orange-500: 158 47 40;
    --color-orange-600: 126 30 35;
    --color-orange-700: 93 22 27;
    --color-orange-800: 66 14 17;
    --color-orange-900: 66 14 17;
    --color-green-0: 239 255 237;
    --color-green-50: 203 244 201;
    --color-green-100: 133 217 150;
    --color-green-200: 51 194 127;
    --color-green-300: 30 166 114;
    --color-green-400: 9 130 93;
    --color-green-500: 14 98 69;
    --color-green-600: 13 75 59;
    --color-green-700: 11 55 51;
    --color-green-800: 8 36 41;
    --color-green-900: 8 36 41;
    --color-blue-0: 240 245 255;
    --color-blue-50: 206 222 253;
    --color-blue-100: 173 199 252;
    --color-blue-200: 133 170 245;
    --color-blue-300: 108 148 236;
    --color-blue-400: 90 130 222;
    --color-blue-500: 75 112 204;
    --color-blue-600: 63 93 179;
    --color-blue-700: 50 73 148;
    --color-blue-800: 37 53 112;
    --color-blue-900: 25 34 74;
    --color-text-base: rgb(var(--color-gray-800) / 1);
    --color-text-muted: rgb(var(--color-gray-500) / 1);
    --color-text-disabled: rgb(var(--color-gray-400) / 1);
    --color-text-primary: rgb(var(--color-blue-600) / 1);
    --color-text-warning: rgb(var(--color-orange-600) / 1);
    --color-text-danger: rgb(var(--color-red-600) / 1);
    --color-bg-app: rgb(var(--color-gray-100) / 1);
    --color-bg-menu-item-hover: rgb(var(--color-gray-100) / 1);
    --color-border-base: rgb(var(--color-gray-200) / 1)
}

*, :after, :before {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:;
    --tw-pan-y:;
    --tw-pinch-zoom:;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:;
    --tw-slashed-zero:;
    --tw-numeric-figure:;
    --tw-numeric-spacing:;
    --tw-numeric-fraction:;
    --tw-ring-inset:;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #3b82f680;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:;
    --tw-brightness:;
    --tw-contrast:;
    --tw-grayscale:;
    --tw-hue-rotate:;
    --tw-invert:;
    --tw-saturate:;
    --tw-sepia:;
    --tw-drop-shadow:;
    --tw-backdrop-blur:;
    --tw-backdrop-brightness:;
    --tw-backdrop-contrast:;
    --tw-backdrop-grayscale:;
    --tw-backdrop-hue-rotate:;
    --tw-backdrop-invert:;
    --tw-backdrop-opacity:;
    --tw-backdrop-saturate:;
    --tw-backdrop-sepia:
}

::-webkit-backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:;
    --tw-pan-y:;
    --tw-pinch-zoom:;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:;
    --tw-slashed-zero:;
    --tw-numeric-figure:;
    --tw-numeric-spacing:;
    --tw-numeric-fraction:;
    --tw-ring-inset:;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #3b82f680;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:;
    --tw-brightness:;
    --tw-contrast:;
    --tw-grayscale:;
    --tw-hue-rotate:;
    --tw-invert:;
    --tw-saturate:;
    --tw-sepia:;
    --tw-drop-shadow:;
    --tw-backdrop-blur:;
    --tw-backdrop-brightness:;
    --tw-backdrop-contrast:;
    --tw-backdrop-grayscale:;
    --tw-backdrop-hue-rotate:;
    --tw-backdrop-invert:;
    --tw-backdrop-opacity:;
    --tw-backdrop-saturate:;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:;
    --tw-pan-y:;
    --tw-pinch-zoom:;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:;
    --tw-slashed-zero:;
    --tw-numeric-figure:;
    --tw-numeric-spacing:;
    --tw-numeric-fraction:;
    --tw-ring-inset:;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #3b82f680;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:;
    --tw-brightness:;
    --tw-contrast:;
    --tw-grayscale:;
    --tw-hue-rotate:;
    --tw-invert:;
    --tw-saturate:;
    --tw-sepia:;
    --tw-drop-shadow:;
    --tw-backdrop-blur:;
    --tw-backdrop-brightness:;
    --tw-backdrop-contrast:;
    --tw-backdrop-grayscale:;
    --tw-backdrop-hue-rotate:;
    --tw-backdrop-invert:;
    --tw-backdrop-opacity:;
    --tw-backdrop-saturate:;
    --tw-backdrop-sepia:
}

.input, .input-wrapper {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 6px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-gray-300) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-white) / var(--tw-bg-opacity));
    line-height: 1.25;
    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
    height:2.375rem
}

.input:hover, .input-wrapper:hover {
    --tw-border-opacity: 1;
    border-color:rgb(var(--color-gray-400) / var(--tw-border-opacity))
}

.input {
    padding-left: .75rem;
    padding-right:.75rem
}

.input:-ms-input-placeholder, .input-wrapper:-ms-input-placeholder {
    color:var(--color-text-disabled)
}

.input::placeholder, .input-wrapper::placeholder {
    color:var(--color-text-disabled)
}

.input:disabled, .input-wrapper:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-gray-300) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-gray-0) / var(--tw-bg-opacity));
    cursor:not-allowed
}

.input:focus, .input-wrapper:focus-within {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-gray-400) / var(--tw-border-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.input-error {
    --tw-border-opacity: 1;
    border-color:rgb(var(--color-red-200) / var(--tw-border-opacity))
}

.select {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-gray-300) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-white) / var(--tw-bg-opacity));
    padding: .5rem .75rem;
    line-height:1.25
}

.select:-ms-input-placeholder {
    color:var(--color-text-disabled)
}

.select::placeholder {
    color:var(--color-text-disabled)
}

.select:hover {
    --tw-border-opacity: 1;
    border-color:rgb(var(--color-gray-400) / var(--tw-border-opacity))
}

.select:focus {
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-gray-300) / var(--tw-border-opacity));
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.select-with-arrow {
    position:relative
}

.select-with-arrow .select {
    width:100%
}

.select-with-arrow:after {
    position: absolute;
    top: 50%;
    right: .5rem;
    height: .4em;
    width: .6em;
    background-color: currentColor;
    opacity: .6;
    content: "";
    transform: translate(-.3em, -.15em);
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path:polygon(100% 0%, 0 0%, 50% 100%)
}

.picker {
    border-radius: .375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-gray-300) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-white) / var(--tw-bg-opacity));
    box-shadow:0 1px #0000000d
}

.picker:focus {
    border-color: transparent;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.picker-menu {
    border-radius: .375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-gray-300) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-white) / var(--tw-bg-opacity));
    padding: .5rem .75rem;
    line-height: 1.25;
    box-shadow:0 5px 15px #00000014
}

.radio {
    height: 1rem;
    width: 1rem;
    flex-shrink: 0;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 9999px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(var(--color-gray-300) / var(--tw-border-opacity));
    --tw-shadow: 0 1px 1px rgba(0, 0, 0, .04);
    --tw-shadow-colored: 0 1px 1px var(--tw-shadow-color);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.radio:checked {
    border-width: 5px;
    --tw-border-opacity: 1;
    border-color:rgb(var(--color-blue-500) / var(--tw-border-opacity))
}

.radio:focus:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.toggle {
    position: relative;
    height: 1.25rem;
    width: 2.5rem;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    /*background-color: rgb(var(--color-gray-300) / var(--tw-bg-opacity));*/
    transition:background-color .2s ease-in-out
}

.toggle:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-gray-200) / var(--tw-bg-opacity));
    cursor:not-allowed
}

.toggle:checked {
    --tw-bg-opacity: 1;
    background-color:rgb(var(--color-blue-500) / var(--tw-bg-opacity))
}

.toggle:checked:disabled {
    --tw-bg-opacity: 1;
    background-color:rgb(var(--color-blue-300) / var(--tw-bg-opacity))
}

.toggle:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.toggle:after {
    position: absolute;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-white) / var(--tw-bg-opacity));
    will-change: width;
    margin: .1875rem;
    height: .875rem;
    width: .875rem;
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    content: " ";
    transition:width .2s ease, transform .2s ease
}

.toggle:checked:after {
    --tw-translate-x: 1.25rem;
    transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.toggle:checked:disabled:after {
    --tw-bg-opacity: 1;
    background-color:rgb(var(--color-blue-50) / var(--tw-bg-opacity))
}

.toggle:enabled:active:after {
    width:1.125rem
}

.toggle:checked:enabled:active:after {
    width: 1.125rem;
    --tw-translate-x: .875rem;
    transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.toggle-large {
    height: 1.5rem;
    width:3rem
}

.toggle-large:after {
    margin: .25rem;
    height: 1rem;
    width:1rem
}

.toggle-large:checked:after {
    --tw-translate-x: 1.5rem;
    transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.toggle-large:enabled:active:after {
    width:1.5rem
}

.toggle-large:checked:enabled:active:after {
    width: 1.5rem;
    --tw-translate-x: 1rem;
    transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.toggle-small {
    height: .75rem;
    width:1.5rem
}

.toggle-small:focus {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    outline: 2px solid transparent;
    outline-offset:2px
}

.toggle-small:after {
    margin: .125rem;
    height: .5rem;
    width:.5rem
}

.toggle-small:checked:after {
    --tw-translate-x: .75rem;
    transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.toggle-small:enabled:active:after {
    width:.675rem
}

.toggle-small:checked:enabled:active:after {
    width: .675rem;
    --tw-translate-x: .55rem;
    transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}



* { 
    box-sizing: border-box; 
    transition: background-color .3s ease, 
                color .3s ease, 
                border-color .3s ease, fill .3s ease; 
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    font-weight: 500;
    font-size: 12px!important;
    background-color: rgb(0,0,0,0);
}

input[type='text'],
input[type='search'],
input[type='email'] {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    font-size: 12px!important;
}

code { font-size: 14px; }
table { cell-spacing: 0; border-collapse: collapse;  }
td {color: inherit;  padding-bottom: 16px; padding-top: 8px; border-bottom-width: 1px; border-bottom-style: solid;  }
th { color: inherit; padding-bottom: 8px; }
article { display: flex; justify-content: space-between; align-items: center; }


/*
*, html *, body *, html body * {
    background: #000!important;
    color: #fff!important;
    border-color: #fff!important;
    fill: currentColor!important;
}
*/
